import Cookies from 'js-cookie'
import router from '@/router'
import { isBot } from '@/utils'

/** 基础数据 */
export const basicData = () => {
  const basicData = {}
  const route = router.currentRoute.value
  if (route?.query?.utm_source || route?.query?.utm_medium || route?.query?.utm_campaign) {
    Cookies.set(
      'utm',
      `${JSON.stringify({
        // eslint-disable-next-line camelcase
        utm_source: route?.query?.utm_source || '',
        utm_medium: route?.query?.utm_medium || '',
        // eslint-disable-next-line camelcase
        utm_campaign: route?.query?.utm_campaign || ''
      })}`
    )
  }
  if (Cookies.get('utm')) {
    try {
      const utm = JSON.parse(decodeURIComponent(Cookies.get('utm')))
      for (const key in utm) {
        if (utm[key]) {
          basicData[key] = utm[key]
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  return basicData
}

/** 页面 */
export const pageview = ({ ...other }) => {
  if (isBot()) return
  const trackingContent = {
    ...basicData(),
    event: 'scnOpen',
    ...other
  }
  window.dataLayer.push(trackingContent)
}

/** 點擊 */
export const action = ({ ...other }) => {
  if (isBot()) return
  const trackingContent = {
    ...basicData(),
    event: 'btnClick',
    ...other
  }
  window.dataLayer.push(trackingContent)
}

/** 高亮 */
export const impression = ({ ...other }) => {
  if (isBot()) return
  const trackingContent = {
    ...basicData(),
    event: 'impression',
    ...other
  }
  window.dataLayer.push(trackingContent)
}

/** 弹出 */
export const popUp = ({ ...other }) => {
  if (!isBot()) return
  const trackingContent = {
    ...basicData(),
    event: 'popUp',
    ...other
  }
  window.dataLayer.push(trackingContent)
}
