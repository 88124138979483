<script setup name="TvbImage">
/** 图片加载 */
import { ref, inject } from 'vue'
import { UseImage } from '@vueuse/components'
import { useElementVisibility } from '@vueuse/core'
const props = defineProps({
  src: {
    type: [Object, String],
    default: null
  },
  icon: {
    type: [Object, String],
    default: null
  },
  iconText: { type: String, default: '' },
  alt: { type: String, default: '' },
  isVideo: { type: Boolean, default: false },
  transform: { type: Boolean, default: false },
  backgroundc: { type: String, default: '#F8F9FA' },
  isLoadingIcon: { type: Boolean, default: true },
  border: { type: Boolean, default: false },
  borderRadius: { type: Number, default: 0 },
  playIcon: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const imgSrc = ref(props.src?.large || props?.src)
const showImg = ref(false)
const showImgEl = ref(false)
const size = inject('size')
const isSmallError = ref(false)
const tvbImageRef = ref()
const isShowImage = useElementVisibility(tvbImageRef)
</script>

<template>
  <div
    class="tvb-image"
    :class="{
      transform: transform,
      load: showImgEl,
      [size]: true,
      'tvb-hairline--surround': border
    }"
    :style="{
      '--background': backgroundc,
      '--tvb-hairline-border-radius': borderRadius ? `calc(2 * ${borderRadius}px)` : null,
      borderRadius: borderRadius ? `${borderRadius}px` : null
    }"
    ref="tvbImageRef"
  >
    <n-tooltip v-if="icon?.medium" placement="top" trigger="hover" :disabled="!iconText">
      <template #trigger>
        <tvb-image
          class="tvb-image_icon"
          :src="icon"
          :is-loading-icon="false"
          :alt="iconText"
          backgroundc="transparent"
          :style="{
            borderRadius: ['s', 'xs'].includes(size) ? '0 6px 0 6px' : '0px 8px 0 8px',
            overflow: 'hidden'
          }"
        />
        <!-- backgroundc="transparent" -->
      </template>
      {{ iconText }}
    </n-tooltip>
    <UseImage
      :src="imgSrc"
      @load="showImgEl = true"
      class="tvb-image_el"
      v-bind="$attrs"
      :alt="alt"
    >
      <template #loading>
        <img class="tvb-image_el" v-show="showImg" :src="imgSrc" alt="placeholder" />

        <tvb-svg-icon
          class="tvb-image_placeholder"
          v-show="!showImg && isLoadingIcon"
          src="/image/img_header_logo@2x.png"
          alt="placeholder"
          name="img_header_logo"
        />
      </template>

      <template #error>
        <tvb-svg-icon
          class="tvb-image_placeholder"
          v-if="isLoadingIcon"
          alt="placeholder"
          name="img_header_logo"
        />
      </template>
    </UseImage>

    <img
      class="tvb-image_small"
      :src="src.small"
      :style="{ opacity: showImg ? '1' : '0' }"
      @load="showImg = true"
      @error="isSmallError = true"
      v-if="!isSmallError && isShowImage && !showImgEl && typeof props.src !== 'string'"
      v-bind="$attrs"
      :alt="alt"
    />
    <tvb-play-icon v-if="isVideo" v-bind="playIcon" class="tvb-image_play-icon" />
  </div>
</template>

<style lang="scss" scoped>
.tvb-image {
  --img-hover-transition: transform 0.5s;
  width: 100%;
  height: 100%;
  background: var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  @include safari-overflow-hidden();

  &.tvb-hairline--surround {
    &::after {
      z-index: 9;
    }
  }

  &.load {
    background: transparent;
  }

  .tvb-image_el {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    opacity: 1;
    transition: var(--img-hover-transition);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 8;
    border-radius: inherit;
  }

  .tvb-image_small {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transition: var(--img-hover-transition);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 5;
  }

  .tvb-image_placeholder {
    width: 98px;
    height: 37px;
    transition: var(--img-hover-transition);
    position: relative;
    z-index: 0;
    object-fit: contain;
  }

  .tvb-image_play-icon {
    position: absolute;
    z-index: 9;
  }

  &.transform:not(.s):not(.xs):hover {
    .tvb-image_small,
    .tvb-image_el {
      transform: scale(1.04);
    }
    .tvb-play-icon {
      background: rgba(0, 0, 0, 0.8);
    }

    .tvb-image_placeholder,
    .tvb-image_icon {
      transform: scale(1);
    }
  }
  .tvb-image_icon {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
  }
  &.s,
  &.xs {
    .tvb-image_icon {
      height: 24px;
      width: 24px;
    }

    .tvb-image_placeholder {
      width: 77px;
      height: 28px;
    }
  }
}
</style>
